<template>
  <section class="hero-section text-center">
    <div v-if="showRecorder" class="recorder-wrapper">
      <ScreenRecorder
        :screen-stream="screenStream"
        :type="recorderType"
        @close="closeRecorder"
      />
    </div>

    <div v-else class="static-content">
      <div class="recorder-options flex flex-between">
        <div
          @click="selectType('screen')"
          :class="{ selected: recorderType === 'screen' }"
        >
          <img src="../assets/option-screen.png" alt="" />
          <span>Screen</span>
        </div>
        <div
          @click="selectType('screen-webcam')"
          :class="{ selected: recorderType === 'screen-webcam' }"
        >
          <img src="../assets/option-screen-webcam.png" alt="" />
          <span>Screen & Webcam</span>
        </div>
      </div>

      <button
        class="flex-center"
        :disabled="!recorderType"
        @click="startRecording"
      >
        <span />
        Start Recording
      </button>
    </div>
  </section>
</template>

<script setup>
  import { ref } from 'vue';
  import ScreenRecorder from '@/components/ScreenRecorder.vue';
  import BaseModal from '@/components/BaseModal.vue';

  const features = [
    'No watermark',
    'Share, without downloading',
    '1080p High-res recordings',
    'Simple Editor',
    'Add captions automatically',
    ''
  ];
  const recorderType = ref('');
  const showRecorder = ref(false);
  let screenStream = ref({});

  async function startRecording () {
    try {
      screenStream.value = await navigator.mediaDevices.getDisplayMedia({
        video: true
      });
      showRecorder.value = true;
    } catch (err) {
      console.error('Error: ' + err);
    }
  }

  function stopScreenSharing () {
    if (screenStream.value) {
      const tracks = screenStream.value.getTracks();
      tracks.forEach((track) => {
        if (track.kind === 'video' && track.label.includes('screen')) {
          track.stop();
        }
      });
    }
  }

  function closeRecorder () {
    stopScreenSharing();
    showRecorder.value = false;
  }

  function selectType (type) {
    recorderType.value = type;
  }
</script>

<style lang="scss">
  @import "../css/vars";

  .hero-section {
    @media only screen and (min-width: 0) {
      .static-content {
        h1,
        p,
        ul {
          max-width: 335px;
        }
        h1 {
          margin: 50px auto 20px;
        }
        p {
          font-size: 1.4rem;
        }
        .features-list {
          margin: 15px auto 26px;
          padding-left: 30px;
          li {
            padding-right: 21px;
            list-style-type: disc;
            white-space: nowrap;
            color: $secondary-purple;
            font-size: 1.4rem;
            line-height: 2rem;
          }
        }
        .recorder-options {
          max-width: 330px;
          margin: 0 auto;
          &:hover {
            div {
              opacity: 0.5;
            }
          }
          div {
            width: 161px;
            height: 141px;
            overflow: hidden;
            background-color: $secondary-black;
            border-radius: 6px;
            transition: opacity 0.3s ease-in-out;
            &:hover {
              opacity: 1 !important;
            }
            img {
              transform: translate(0px, -6px);
            }
            span {
              transform: translateY(4px);
              display: block;
              font-size: 1.2rem;
              color: #f6f6f6;
            }
          }
        }

        > button {
          width: 226px;
          height: 44px;
          margin: 20px auto 0;
          background-color: $btn-red;
          color: white;
          font-size: 1.3rem;
          border-radius: 8px;
          margin-bottom: 50px;
          span {
            width: 7px;
            height: 7px;
            margin-right: 6px;
            border-radius: 50%;
            background-color: white;
          }
        }
      }
      .recorder-wrapper {
        max-width: 70%;
        margin: 0 auto;
        padding-top: 80px;
      }
    }

    @media only screen and (min-width: 1024px) {
      .static-content {
        h1 {
          max-width: 880px;
        }
        p {
          max-width: 100%;
          font-size: 1.8rem;
        }
        .features-list {
          max-width: 100%;
          margin-top: 34px;
          margin-bottom: 56px;
          font-size: 1.6rem;
        }
        .recorder-options {
          max-width: 476px;
          div {
            width: 220px;
            height: 192px;
            cursor: pointer;
            span {
              font-size: 1.6rem;
            }
            &.selected {
              outline: 5px solid $secondary-purple;
              border-radius: 8px;
            }
          }
        }
        > button {
          margin-top: 24px;
        }
      }
    }
  }
</style>
