import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    shareUrl: null
  },
  mutations: {
    SET_SHARE_URL (state, url) {
      state.shareUrl = url;
    }
  },
  actions: {
    async uploadVideo ({ commit }, videoBlob) {
      const formData = new FormData();
      formData.append('video', videoBlob, 'screen-recording.mp4');

      try {
        const response = await axios.post(
          'https://riverside.testenv.ro/api/recorder/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        commit('SET_SHARE_URL', response.data.shareUrl);
        return response.data.shareUrl;
      } catch (error) {
        console.error('Error uploading video:', error);
        throw error;
      }
    }
  }
});
