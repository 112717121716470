<template>
  <div class="screen-recorder">
    <BaseModal v-if="isShareModal">
      <div class="share-component">
        <i class="close-share absolute pointer" @click="handleModalClose">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="19.8667"
              height="19.8667"
              rx="9.93337"
              fill="#2B2B2B"
            />
            <path
              d="M12.2985 7.5683L7.56836 12.2985M7.56836 7.5683L12.2985 12.2985"
              stroke="#DBDBDB"
              stroke-width="1.06429"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </i>
        <p class="text-center">Share with others</p>
        <ul class="flex flex-between">
          <li
            v-for="(item, index) in shareOptions"
            :key="index"
            class="pointer"
            @click="item.action"
          >
            <div id="share-icon"><img :src="item.img" alt="" /></div>
            <p id="share-text">{{ item.text }}</p>
          </li>
        </ul>
      </div>
      <div v-if="showClipboardPopup" class="clipboard-popup">
        Copied to clipboard
      </div>
    </BaseModal>
    <div class="video-container relative">
      <i class="close-icon absolute pointer" @click="handleClose">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="19.8667" height="19.8667" rx="9.93337" fill="#2B2B2B" />
          <path
            d="M12.2985 7.5683L7.56836 12.2985M7.56836 7.5683L12.2985 12.2985"
            stroke="#DBDBDB"
            stroke-width="1.06429"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
      <div
        v-if="prepareRecording"
        class="record-countdown absolute left0 top0 wh100 flex-center z99"
      >
        <span class="flex-center text-white">{{ countdown }}</span>
      </div>
      <video v-show="showVideoPlayer" ref="videoPlayer" controls />
      <div v-show="!showVideoPlayer" class="preview-container">
        <video ref="screenVideoElement" autoplay muted />
        <div class="camera-preview-wrapper">
          <video
            v-show="type === 'screen-webcam'"
            ref="cameraVideoElement"
            autoplay
            muted
            class="camera-preview absolute"
            @mousedown="startDrag"
            :style="{ left: `${cameraX}px`, top: `${cameraY}px` }"
          />
        </div>
      </div>
    </div>
    <canvas ref="canvas" style="display: none" />

    <div class="controls">
      <div v-if="recState === 'recording'" class="recording-mode-actions flex">
        <span id="time-counter" class="flex-center">
          <span />
          {{ formattedTime }}
        </span>
        <button @click="togglePause">
          {{ isPaused ? "▶" : "⏸" }}
        </button>
        <button @click="stopRecording">⏹</button>
      </div>

      <div v-if="recState === 'standBy'" class="flex record-button-wrapper">
        <button
          id="record-btn"
          class="flex-center m-r-10"
          @click="handlePrepareRecording()"
        >
          <span class="flex-center">
            <span />
          </span>
          Record
        </button>

        <div class="select-list m-r-10">
          <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6663 9V9.83333C14.6663 13.5152 11.6816 16.5 7.99967 16.5C4.31778 16.5 1.33301 13.5152 1.33301 9.83333V9M7.99967 13.1667C6.15873 13.1667 4.66634 11.6743 4.66634 9.83333V4.83333C4.66634 2.99238 6.15873 1.5 7.99967 1.5C9.84062 1.5 11.333 2.99238 11.333 4.83333V9.83333C11.333 11.6743 9.84062 13.1667 7.99967 13.1667Z"
              stroke="#FAFAFA"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <select
            v-model="selectedAudioDevice"
            @change="handleAudioDeviceChange"
          >
            <option value="muted">No Microphone</option>
            <option
              v-for="device in uniqueAudioDevices"
              :key="device.deviceId"
              :value="device.deviceId"
            >
              {{ device.label }}
            </option>
          </select>
        </div>

        <div v-if="type === 'screen-webcam'" class="select-list">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3337 7.4428C18.3337 6.93795 18.3337 6.68553 18.2338 6.56864C18.1472 6.46722 18.0173 6.4134 17.8843 6.42386C17.7311 6.43592 17.5526 6.61441 17.1956 6.97139L14.167 9.99999L17.1956 13.0286C17.5526 13.3856 17.7311 13.5641 17.8843 13.5761C18.0173 13.5866 18.1472 13.5328 18.2338 13.4313C18.3337 13.3145 18.3337 13.062 18.3337 12.5572V7.4428Z"
              stroke="#FAFAFA"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.66699 8.16666C1.66699 6.76653 1.66699 6.06646 1.93948 5.53168C2.17916 5.06128 2.56161 4.67882 3.03202 4.43914C3.5668 4.16666 4.26686 4.16666 5.66699 4.16666H10.167C11.5671 4.16666 12.2672 4.16666 12.802 4.43914C13.2724 4.67882 13.6548 5.06128 13.8945 5.53168C14.167 6.06646 14.167 6.76653 14.167 8.16666V11.8333C14.167 13.2335 14.167 13.9335 13.8945 14.4683C13.6548 14.9387 13.2724 15.3212 12.802 15.5608C12.2672 15.8333 11.5671 15.8333 10.167 15.8333H5.66699C4.26686 15.8333 3.5668 15.8333 3.03202 15.5608C2.56161 15.3212 2.17916 14.9387 1.93948 14.4683C1.66699 13.9335 1.66699 13.2335 1.66699 11.8333V8.16666Z"
              stroke="#FAFAFA"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <select
            v-model="selectedCameraDevice"
            @change="handleCameraDeviceChange"
          >
            <option
              v-for="device in cameraDevices"
              :key="device.deviceId"
              :value="device.deviceId"
            >
              {{ device.label || `Camera ${device.deviceId.slice(0, 5)}...` }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="recState === 'ended'" class="ended-action flex">
        <button @click="handleDownload">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 16.5H1.5M14 8.16667L9 13.1667M9 13.1667L4 8.16667M9 13.1667V1.5"
              stroke="#FAFAFA"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Download
        </button>
        <button @click="handleShare">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.3261 10.5062C17.5296 10.3318 17.6313 10.2446 17.6686 10.1409C17.7013 10.0498 17.7013 9.9502 17.6686 9.85914C17.6313 9.75539 17.5296 9.6682 17.3261 9.49383L10.2672 3.44331C9.917 3.14315 9.74191 2.99306 9.59367 2.98939C9.46483 2.98619 9.34177 3.04279 9.26035 3.14269C9.16667 3.25764 9.16667 3.48825 9.16667 3.94948V7.52886C7.38777 7.84007 5.75966 8.74146 4.54976 10.0949C3.23069 11.5704 2.50103 13.48 2.5 15.4591V15.9691C3.37445 14.9157 4.46626 14.0638 5.70063 13.4716C6.78891 12.9495 7.96535 12.6403 9.16667 12.5588V16.0505C9.16667 16.5117 9.16667 16.7424 9.26035 16.8573C9.34177 16.9572 9.46483 17.0138 9.59367 17.0106C9.74191 17.0069 9.917 16.8569 10.2672 16.5567L17.3261 10.5062Z"
              stroke="#FAFAFA"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Share
        </button>
        <button @click="handleRetake">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66699 8.33333C1.66699 8.33333 3.33781 6.05685 4.69519 4.69854C6.05257 3.34022 7.92832 2.5 10.0003 2.5C14.1425 2.5 17.5003 5.85786 17.5003 10C17.5003 14.1421 14.1425 17.5 10.0003 17.5C6.58108 17.5 3.69625 15.2119 2.79346 12.0833M1.66699 8.33333V3.33333M1.66699 8.33333H6.66699"
              stroke="#FAFAFA"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Retake
        </button>
      </div>
    </div>

    <div v-if="isConverting" class="conversion-overlay">
      <p>Converting to MP4... {{ conversionProgress }}%</p>
    </div>
  </div>
</template>

<script setup>
  import { FFmpeg } from '@ffmpeg/ffmpeg';
  import { fetchFile, toBlobURL } from '@ffmpeg/util';
  import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
  import BaseModal from '@/components/BaseModal.vue';
  import copyIcon from '../assets/share/copy.png';
  import twitterIcon from '../assets/share/twitter.png';
  import fbIcon from '../assets/share/Facebook.png';
  import linkedin from '../assets/share/linkedin.png';
  import embed from '../assets/share/embed.png';
  import { useStore } from 'vuex';

  const props = defineProps({
    type: String,
    screenStream: Object
  });
  const ffmpeg = new FFmpeg();
  const isFFmpegLoaded = ref(false);
  const isConverting = ref(false);
  const conversionProgress = ref(0);
  const currentVideoFilename = ref(null);
  const videoPlayer = ref(null);
  const recordedVideoBlob = ref({});
  const showVideoPlayer = ref(false);
  const isRecording = ref(false);
  const isPaused = ref(false);
  const screenVideoElement = ref(null);
  const cameraVideoElement = ref(null);
  const recordingTime = ref(0);
  const timerInterval = ref(null);
  const selectedAudioDevice = ref('');
  const selectedCameraDevice = ref('');
  const audioDevices = ref([]);
  const cameraDevices = ref([]);
  const recState = ref('standBy');
  const countdown = ref(3);
  const canvas = ref(null);
  let countdownInterval = undefined;
  const prepareRecording = ref(false);
  const cameraX = ref(0);
  const cameraY = ref(350);
  const isDragging = ref(false);
  const dragStartX = ref(0);
  const dragStartY = ref(0);

  const formattedTime = computed(() => {
    const hours = Math.floor(recordingTime.value / 3600);
    const minutes = Math.floor((recordingTime.value % 3600) / 60);
    const seconds = recordingTime.value % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}:00`;
  });
  const emit = defineEmits(['close']);
  let mediaRecorder = undefined;
  let recordedChunks = [];
  let webcamVideoStream;
  let audioStream;
  const isShareModal = ref(false);
  const shareOptions = ref([
    { img: copyIcon, text: 'Copy', action: copyShareUrl },
    { img: fbIcon, text: 'Facebook', action: shareToFacebook },
    { img: twitterIcon, text: 'Twitter', action: shareToTwitter },
    { img: embed, text: 'Embed', action: showEmbedCode }
  ]);
  const { state, dispatch, commit } = useStore();
  const timeLimit = 30 * 60;
  const shareUrl = computed(() => state.shareUrl);
  const showClipboardPopup = ref(false);

  async function loadFFmpeg () {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd';
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        'application/wasm'
      )
    });

    ffmpeg.on('progress', ({ progress }) => {
      conversionProgress.value = Math.round(progress * 100);
    });

    isFFmpegLoaded.value = true;
  }

  async function convertToMp4 (inputBlob) {
    if (!isFFmpegLoaded.value) {
      console.error('FFmpeg is not loaded');
      return null;
    }

    isConverting.value = true;
    conversionProgress.value = 0;

    const inputBuffer = await inputBlob.arrayBuffer();
    await ffmpeg.writeFile('input.webm', new Uint8Array(inputBuffer));

    // await ffmpeg.exec(['-i', 'input.webm', '-c:v', 'libx264', '-preset', 'fast', '-crf', '22', '-c:a', 'aac', '-b:a', '128k', 'output.mp4']);
    await ffmpeg.exec([
      '-i',
      'input.webm',
      '-c:v',
      'libx264',
      '-preset',
      'veryfast',
      '-crf',
      '23',
      '-c:a',
      'aac',
      '-b:a',
      '128k',
      'output.mp4'
    ]);

    const outputData = await ffmpeg.readFile('output.mp4');
    const outputBlob = new Blob([outputData.buffer], { type: 'video/mp4' });

    await ffmpeg.deleteFile('input.webm');
    await ffmpeg.deleteFile('output.mp4');

    isConverting.value = false;
    return outputBlob;
  }

  function createVideoBlob () {
    recordedVideoBlob.value = new Blob(recordedChunks, {
      type: 'video/webm'
    });
    convertAndShowVideo();
  }

  let mp4Blob = null;

  async function convertAndShowVideo () {
    try {
      if (recordedVideoBlob.value) {
        mp4Blob = await convertToMp4(recordedVideoBlob.value);
        if (mp4Blob) {
          showVideoPlayer.value = true;
          if (videoPlayer.value) {
            videoPlayer.value.src = URL.createObjectURL(mp4Blob);
          }

          try {
            await dispatch('uploadVideo', mp4Blob);
          } catch (error) {
            console.error('Error uploading video:', error);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleDownload () {
    if (recordedVideoBlob.value) {
      const url = URL.createObjectURL(mp4Blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'screen-recording.mp4';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }

  function handleModalClose () {
    isShareModal.value = false;
  }

  function handleClose () {
    emit('close');
    if (webcamVideoStream) {
      const tracks = webcamVideoStream.getTracks();
      tracks.forEach((track) => {
        if (track.kind === 'video') {
          track.stop();
        }
      });
    }
  }

  function handleRetake () {
    recordedChunks = [];
    showVideoPlayer.value = false;
    handlePrepareRecording();
  }

  function handleShare () {
    isShareModal.value = true;
  }

  async function handleAudioDeviceChange () {
    try {
      if (selectedAudioDevice.value === 'muted') {
        if (audioStream) {
          audioStream.getAudioTracks().forEach((track) => {
            track.enabled = false;
          });
        }
      } else {
        // Get a new stream with the selected audio device
        const newAudioStream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: { exact: selectedAudioDevice.value } }
        });

        // Update the audio track in the audioStream
        if (audioStream) {
          const oldAudioTrack = audioStream.getAudioTracks()[0];
          if (oldAudioTrack) {
            audioStream.removeTrack(oldAudioTrack);
            oldAudioTrack.stop();
          }
        } else {
          audioStream = new MediaStream();
        }
        audioStream.addTrack(newAudioStream.getAudioTracks()[0]);
        audioStream.getAudioTracks()[0].enabled = true;
      }

      // If we're currently recording, we need to update the MediaRecorder
      if (mediaRecorder && mediaRecorder.state === 'recording') {
        // Stop the current recording
        mediaRecorder.stop();

        // Create a new MediaStream with the updated audio track
        let combinedStream;
        if (props.type === 'screen-webcam') {
          combinedStream = new MediaStream([
            ...canvasStream.getVideoTracks(),
            ...props.screenStream.getTracks(),
            ...webcamVideoStream.getTracks(),
            ...audioStream.getAudioTracks()
          ]);
        } else {
          combinedStream = new MediaStream([
            ...props.screenStream.getTracks(),
            ...audioStream.getTracks()
          ]);
        }

        // Create a new MediaRecorder with the updated stream
        mediaRecorder = new MediaRecorder(combinedStream, {
          mimeType: 'video/webm; codecs=vp9'
        });
        mediaRecorder.ondataavailable = handleDataAvailable;
        mediaRecorder.start();
      }
    } catch (error) {
      console.error('Error changing audio device:', error);
    }
  }

  async function handleCameraDeviceChange () {
    if (webcamVideoStream) {
      // Stop all tracks in the current camera stream
      webcamVideoStream.getTracks().forEach((track) => track.stop());
    }
    try {
      // Update the cameraStream ref with the new stream
      webcamVideoStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: selectedCameraDevice.value } }
      });
      cameraVideoElement.value.srcObject = webcamVideoStream;
    } catch (error) {
      console.error('Error changing camera device:', error);
    }
  }

  function handlePrepareRecording () {
    prepareRecording.value = true;
    countdownInterval = setInterval(() => {
      countdown.value -= 1;
      if (countdown.value === 0) {
        startRecording();
        prepareRecording.value = false;
        clearInterval(countdownInterval);
        countdown.value = 3;
      }
    }, 1000);
  }

  async function startRecording () {
    try {
      let combinedStream;
      recState.value = 'recording';

      // ??
      canvas.value.width = 2048;
      canvas.value.height = 1280;

      // Create a stream from the canvas
      const canvasStream = canvas.value.captureStream(30); // 30 fps

      combinedStream = new MediaStream([
        ...props.screenStream.getTracks(),
        ...audioStream.getTracks()
      ]);

      if (props.type === 'screen-webcam') {
        combinedStream = new MediaStream([
          ...canvasStream.getVideoTracks(),
          ...props.screenStream.getTracks(),
          ...webcamVideoStream.getTracks(),
          ...audioStream.getAudioTracks()
        ]);
      }

      mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: 'video/mp4; codecs=vp9'
      });
      mediaRecorder.ondataavailable = handleDataAvailable;
      mediaRecorder.start();
      isRecording.value = true;
      startTimer();
      if (props.type === 'screen-webcam') {
        drawFrame();
      }
    } catch (err) {
      console.log(err);
    }
  }

  function drawFrame () {
    if (!isRecording.value) return;

    const ctx = canvas.value.getContext('2d');

    ctx.drawImage(
      screenVideoElement.value,
      0,
      0,
      canvas.value.width,
      canvas.value.height
    );

    if (props.type === 'screen-webcam' && cameraVideoElement.value) {
      const cameraWidth = canvas.value.width / 5;
      const cameraHeight =
        (cameraWidth / cameraVideoElement.value.videoWidth) *
        cameraVideoElement.value.videoHeight;

      const container = document.querySelector('.video-container');
      const canvasX =
        (cameraX.value / container.offsetWidth) * canvas.value.width;
      const canvasY =
        (cameraY.value / container.offsetHeight) * canvas.value.height;

      ctx.save();

      drawRoundedRectangle(
        ctx,
        canvasX,
        canvasY,
        cameraWidth,
        cameraHeight,
        8,
        4,
        '#9671FF'
      );

      ctx.drawImage(
        cameraVideoElement.value,
        canvasX,
        canvasY,
        cameraWidth,
        cameraHeight
      );

      ctx.restore();
    }

    requestAnimationFrame(drawFrame);
  }

  function drawRoundedRectangle (
    ctx,
    x,
    y,
    width,
    height,
    radius,
    borderWidth,
    borderColor
  ) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();

    ctx.lineWidth = borderWidth;
    ctx.strokeStyle = borderColor;
    ctx.stroke();
    ctx.clip();
  }

  function stopRecording () {
    recState.value = 'ended';
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.onstop = createVideoBlob;
      mediaRecorder.stop();
      isRecording.value = false;
      isPaused.value = false;
      stopTimer();
      resetTimer();
    }
  }
  function handleDataAvailable (event) {
    if (event.data.size > 0) {
      recordedChunks.push(event.data);
    }
  }

  function togglePause () {
    if (!isRecording.value) return;

    if (isPaused.value) {
      mediaRecorder.resume();
      startTimer();
    } else {
      mediaRecorder.pause();
      stopTimer();
    }
    isPaused.value = !isPaused.value;
  }

  function startTimer () {
    stopTimer();
    timerInterval.value = setInterval(() => {
      recordingTime.value++;
      if (recordingTime.value >= timeLimit) {
        stopRecording();
        alert('Recording stopped: 30-minute time limit reached.');
      }
    }, 1000);
  }

  function stopTimer () {
    if (timerInterval.value) {
      clearInterval(timerInterval.value);
      timerInterval.value = null;
    }
  }

  function resetTimer () {
    recordingTime.value = 0;
  }

  async function getAudioCameraDevices () {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      audioDevices.value = devices.filter(
        (device) => device.kind === 'audioinput'
      );
      cameraDevices.value = devices.filter(
        (device) => device.kind === 'videoinput'
      );

      if (cameraDevices.value.length > 0) {
        selectedCameraDevice.value = cameraDevices.value[0].deviceId;
      }
    } catch (error) {
      console.error('Error fetching devices:', error);
    }
  }

  watch(showVideoPlayer, (newValue) => {
    if (newValue && videoPlayer.value && recordedVideoBlob.value) {
      videoPlayer.value.src = URL.createObjectURL(recordedVideoBlob.value);
    }
  });

  onMounted(async () => {
    await loadFFmpeg();
    screenVideoElement.value.srcObject = props.screenStream;
    audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    if (props.type === 'screen-webcam') {
      webcamVideoStream = await navigator.mediaDevices.getUserMedia({
        video: true
      });
      cameraVideoElement.value.srcObject = webcamVideoStream;
    }
    await getAudioCameraDevices();
    if (audioDevices.value.length > 0) {
      selectedAudioDevice.value = audioDevices.value[0].deviceId;
    }
    function sendHeight () {
      var height = document.body.scrollHeight;
      parent.postMessage({ type: 'adjustHeight', height: height }, '*');
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === document.body) {
          sendHeight();
        }
      }
    });
    resizeObserver.observe(document.body);
    sendHeight();
  });

  onUnmounted(() => {
    stopTimer();
    if (screenVideoElement.value) {
      screenVideoElement.value.srcObject = null;
    }
    if (cameraVideoElement.value) {
      cameraVideoElement.value.srcObject = null;
    }
  });

  const uniqueAudioDevices = computed(() => {
    const uniqueDevices = [];
    const seenLabels = new Set();

    for (const device of audioDevices.value) {
      const cleanLabel = device.label
        .replace(/^(Default - |Communications - )?/, '')
        .trim();

      if (!seenLabels.has(cleanLabel)) {
        uniqueDevices.push(device);
        seenLabels.add(cleanLabel);
      }
    }

    return uniqueDevices;
  });

  watch(
    cameraDevices,
    (newDevices) => {
      if (newDevices.length > 0 && !selectedCameraDevice.value) {
        selectedCameraDevice.value = newDevices[0].deviceId;
      }
    },
    { immediate: true }
  );

  function startDrag (event) {
    event.preventDefault();
    isDragging.value = true;
    dragStartX.value = event.clientX - cameraX.value;
    dragStartY.value = event.clientY - cameraY.value;

    document.addEventListener('mousemove', drag);
    document.addEventListener('mouseup', stopDrag);
  }

  function drag (event) {
    if (isDragging.value) {
      const newX = event.clientX - dragStartX.value;
      const newY = event.clientY - dragStartY.value;

      const container = document.querySelector('.video-container');
      const cameraPreview = document.querySelector('.camera-preview');

      if (container && cameraPreview) {
        const maxX = container.offsetWidth - cameraPreview.offsetWidth;
        const maxY = container.offsetHeight - cameraPreview.offsetHeight;

        cameraX.value = Math.max(0, Math.min(newX, maxX));
        cameraY.value = Math.max(0, Math.min(newY, maxY));
      }
    }
  }

  function stopDrag () {
    isDragging.value = false;
    document.removeEventListener('mousemove', drag);
    document.removeEventListener('mouseup', stopDrag);
  }

  function updateCameraPosition () {
    const cameraPreview = document.querySelector('.camera-preview');
    if (cameraPreview) {
      cameraPreview.style.left = `${cameraX.value}px`;
      cameraPreview.style.top = `${cameraY.value}px`;
    }
  }

  function showPopup () {
    showClipboardPopup.value = true;
    setTimeout(() => {
      showClipboardPopup.value = false;
    }, 500);
  }

  function copyToClipboardAndShowPopup (text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showPopup();
      })
      .catch((err) => {
        console.error('Error copying text: ', err);
      });
  }

  function copyShareUrl () {
    copyToClipboardAndShowPopup(shareUrl.value);
  }

  function shareToFacebook () {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl.value)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  function shareToTwitter () {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl.value)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  }
  function showEmbedCode () {
    const embedCode = `<iframe src="${shareUrl.value}" width="640" height="360" frameborder="0" allowfullscreen></iframe>`;
    copyToClipboardAndShowPopup(embedCode);
  }
</script>

<style lang="scss">
  @import "../css/vars";
  @import "../css/mixins";

  .screen-recorder {
    @media only screen and (min-width: 0) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .conversion-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 1.2em;
      }

      .close-icon {
        top: -20px;
        right: -26px;
        transform: scale(1.6);
        transition: transform 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          transform: scale(2);
        }
      }

      video {
        max-width: 100%;
        margin-bottom: 20px;
      }

      .controls {
        display: flex;
        gap: 10px;
        align-items: center;
        button {
          height: 44px;
          border-radius: 8px;
          background-color: $dark-grey;
          color: white;
          font-size: 1.4rem;
        }
        #record-btn,
        #time-counter,
        .recording-mode-actions button,
        select {
          max-width: 200px;
          height: 44px;
          margin-right: 16px;
          border-radius: 8px;
        }
        .record-button-wrapper {
          margin-top: 20px;
        }
        #record-btn {
          width: 102px;
          font-size: 1.3rem;
          font-weight: 600;
          background-color: $btn-red;
          color: white;
          span {
            margin: 0;
            border-radius: 50%;
          }
          > span {
            width: 14px;
            height: 14px;
            margin-right: 6px;
            background-color: white;
            span {
              width: 12px;
              height: 12px;
              border: 2px solid $red;
              display: block;
            }
          }
        }
        .select-list {
          padding: 0 4px 0 19px;
          background-color: $dark-grey;
          border-radius: 8px;
          svg {
            transform: translateY(3px);
          }
          select {
            padding: 0 12px;
            color: white;
            background-color: $dark-grey;
            border: none;
            cursor: pointer;
          }
        }
        #time-counter {
          background-color: white;
          color: $black;
          padding: 0 14px;
          span {
            width: 14px;
            height: 14px;
            margin-right: 6px;
            background-color: $red;
            border-radius: 50%;
          }
        }

        .recording-mode-actions {
          button {
            width: 44px;
            background-color: $dark-grey;
            color: white;
            &:nth-child(2) {
              margin: 0 10px;
            }
          }
        }

        .ended-action {
          button {
            padding: 0 24px;
            font-weight: 600;
            display: flex;
            align-items: center;
            svg {
              margin-right: 8px;
            }
            &:nth-child(2) {
              margin: 0 10px;
            }
          }
        }
      }

      .timer {
        font-family: monospace;
        font-size: 1.2em;
        padding: 5px 10px;
        background-color: #f0f0f0;
        border-radius: 5px;
        cursor: pointer;
        border: none;
      }

      .timer.recording {
        color: red;
      }

      button {
        padding: 5px 10px;
        font-size: 1.2em;
        cursor: pointer;
      }

      button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .record-countdown {
        background-color: rgba(0, 0, 0, 0.7);
        span {
          @include circle(116px, #232323);
          font-weight: 900;
          font-size: 5rem;
        }
      }

      .video-container {
        position: relative;
        width: 100%;
        max-width: 800px;
        margin-bottom: 20px;

        .preview-container {
          > video {
            margin-bottom: 0;
          }
          .camera-preview-wrapper {
            width: 100%;
            height: 100%;
            pointer-events: none;
            .camera-preview {
              pointer-events: auto;
              cursor: grab;
              width: 168px;
              height: 98px;
              object-fit: cover;
              border-radius: 10px;
              border: 4px solid #9671ff;
            }
          }
        }
      }

      .share-component {
        position: relative;
        padding: 20px 28px;
        border-radius: 8px;
        background-color: $dark-grey;
        width: 424px;
        height: 192px;
        p {
          color: #fafafa;
          font-size: 24px;
          font-weight: 800;
          line-height: 30px;
          margin: 10px 0 20px 0;
        }
        li {
          transition: transform 0.2s ease-in-out;
          &:hover {
            transform: scale(1.1);
          }
          #share-icon {
            height: 60px;
            width: 60px;
            text-align: center;
            background-color: $gray;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          #share-text {
            font-size: 12px;
            color: #888888;
            font-weight: 600;
            margin: 8px 0px 12px 0px;
            text-align: center;
          }
        }
        .close-share {
          scale: (0.7);
          top: -20px;
          right: -30px;
          transform: scale(1.6);
          transition: transform 0.2s ease-in-out;
          &:hover {
            transform: scale(2);
          }
        }
      }
      .clipboard-popup {
        position: absolute;
        background-color: $dark-grey;
        bottom: 30%;
        left: 50%;
        color: $white;
        padding: 10px 20px;
        transform: translateX(-50%);
        border-radius: 8px;
        font-size: 14px;
        transition: opacity 2s ease-in-out;
      }
    }
  }
</style>
